import axios from "axios";
import { data } from "uikit";
import convert from "xml-js";

export async function getQuote(params) {
  var data = {};

  data["Product Type"] = params.get("producttype");
  data["Gender"] = params.get("gender");
  data["Age"] = params.get("birthdate");
  data["Face Amount"] = parseInt(params.get("faceamount"));
  data["Province"] = params.get("province");
  data["Smoking"] = params.get("tobacco");

  data["Underwriting Risk"] = "Regular";

  if (data["Product Type"]?.includes("Term")) {
    data["Product Item"] = data["Product Type"]?.replace("Year Term", "year");
    data["Product Type"] = "term";
  } else if (data["Product Type"]?.includes("Permanent")) {
    data["Product Type"] = "term100";
    data["Product Item"] = "All products";
  }

  var res = await axios.get(`https://www.winquote.net/cgi-bin/competei.pl`, {
    params: {
      dc:
        "-cv1.5 -ccca -qt0 -pcca1584649349 -cedv1.0.16.10.11 -rt0 " +
        GenderCode[data["Gender"]] +
        " -alan" +
        data["Age"] +
        " " +
        UnderwritingRisk[data["Underwriting Risk"]] +
        " -fa" +
        data["Face Amount"] +
        " " +
        Smoking[data["Smoking"]] +
        " " +
        ProductType[data["Product Type"]] +
        " " +
        ProductItem[data["Product Type"]][data["Product Item"]] +
        " " +
        Province[data["Province"]] +
        " -pm3 -ofxml -esxml -skiplegend -langen -foencu",
    },
  });

  var payloadData = convert.xml2js(res.data, {
    compact: true,
  });

  var quotes = payloadData.results.record.recordSet
    .filter(
      (record) =>
        record.recordSetData.company._text === "Canada Life" ||
        record.recordSetData.company._text === "RBC Insurance" ||
        record.recordSetData.company._text === "Foresters Life" ||
        record.recordSetData.company._text === "Manulife Financial" ||
        record.recordSetData.company._text === "iA Financial Group" ||
        record.recordSetData.company._text === "CPP underwritten by Foresters"
    )
    .map((record) => {
      return {
        Company: record.recordSetData.company._text,
        Product: record.recordSetData.product._text,
        Class: record.recordSetData.pClass._text,
        "Monthly Premium": record.recordSetData.premium._text,
        "Monthly Premium Text":
          parseInt(record.recordSetData.premium._text) > 9000000
            ? record.recordSetData.premiumText._text
            : null,
      };
    });

  var numberOfQuotes = 0;
  var totalQuote = 0;
  var lowestQuote;
  var canadaLifeQuote;
  quotes.forEach((quote) => {
    if (!quote["Monthly Premium Text"]) {
      if (!lowestQuote) {
        lowestQuote = quote["Monthly Premium"];
      }

      if (quote.Company === "CPP underwritten by Foresters") {
        if (!canadaLifeQuote) {
          canadaLifeQuote = quote["Monthly Premium"];
        }
      }

      totalQuote += parseFloat(quote["Monthly Premium"]);
      numberOfQuotes++;
    }
  });

  return { No_Medical: canadaLifeQuote || "-", Lowest: lowestQuote || "-" };
}

const GenderCode = {
  Male: "-g1",
  Female: "-g2",
};

const Smoking = {
  Yes: "-tucgt1",
  No: "",
};

const Province = {
  "British Columbia": "-lc0",
  Alberta: "-lc1",
  Saskatchewan: "-lc2",
  "Northwest Territories": "-lc3",
  Yukon: "-lc4",
  Nunavut: "-lc5",
  Manitoba: "-lc6",
  Ontario: "-lc7",
  Quebec: "-lc8",
  "Nova Scotia": "-lc9",
  "New Brunswick": "-lc10",
  "Prince Edward Island": "-lc11",
  "Newfoundland & Labrador": "-lc12",
  "All Provinces": "-lc13",
};

const ProductType = {
  term: "-pg0",
  optiterm: "-pg1",
  term100: "-pg2",
  "whole life": "-pg3",
  "universal life": "-pg4",
  "living benefits": "-pg5",
  mortgage: "-pg6",
  supplemental: "-pg7",
};

const ProductItem = {
  term: {
    "1 year": "-pi0",
    "5 year": "-pi1",
    "10 year": "-pi2",
    "15 year": "-pi3",
    "20 year": "-pi4",
    "25 year": "-pi5",
    "30 year": "-pi6",
    "35 year": "-pi7",
    "40 year": "-pi8",
    "Level Term to 65": "-pi9",
    "Level Term to 70": "-pi10",
    "Level Term to 75": "-pi11",
    "special term": "-pi12",
    "All Products": "-pi13",
  },
  optiterm: {
    "10 yr Term": "-pi0",
    "11 Yr Term": "-pi1",
    "12 Yr Term": "-pi2",
    "13 Yr Term": "-pi3",
    "14 Yr Term": "-pi4",
    "15 Yr Term": "-pi5",
    "16 yr Term": "-pi6",
    "17 Yr Term": "-pi7",
    "18 Yr Term": "-pi8",
    "19 Yr Term": "-pi9",
    "20 yr Term": "-pi10",
    "21 Yr Term": "-pi11",
    "22 Yr Term": "-pi12",
    "23 Yr Term": "-pi13",
    "24 Yr Term": "-pi14",
    "25 Yr Term": "-pi15",
    "26 yr Term": "-pi16",
    "27 Yr Term": "-pi17",
    "28 Yr Term": "-pi18",
    "29 Yr Term": "-pi19",
    "30 yr Term": "-pi20",
    "31 Yr Term": "-pi21",
    "32 Yr Term": "-pi22",
    "33 Yr Term": "-pi23",
    "34 Yr Term": "-pi24",
    "35 Yr Term": "-pi25",
    "36 yr Term": "-pi26",
    "37 Yr Term": "-pi27",
    "38 Yr Term": "-pi28",
    "39 Yr Term": "-pi29",
    "40 yr Term": "-pi30",
    "All products": "-pi31",
  },
  term100: {
    "Traditional Term": "-pi0",
    "T100 20-Pay": "-pi1",
    "Unique Term 100": "-pi2",
    "All products": "-pi3",
  },
  "whole life": {
    "WL / Life Pay": "-pi0",
    "WL / Pay to 65": "-pi1",
    "WL / 25 to Pay": "-pi2",
    "WL / 20 to Pay": "-pi3",
    "WL / 15 to Pay": "-pi4",
    "WL / Quick Pay": "-pi5",
    "WL / Adjustable Life Pay": "-pi6",
    "WL / Adjustable Pay to 65": "-pi7",
    "WL / Adjustable 10-20 Pay": "-pi8",
    "Unique Whole Life": "-pi9",
    "All Products": "-pi10",
  },
  "universal life": {
    "UL / T100 Min. Premium": "-pi0",
    "UL / Pay to 65 Min. Premium": "-pi1",
    "UL / 20 Pay Min. Premium": "-pi2",
    "UL / 15 Pay Min. Premium": "-pi3",
    "UL / 10 Pay Min. Premium": "-pi4",
    "UL / Term 10 Pay Min. Premium": "-pi5",
    "UL / Term 20 Pay Min. Premium": "-pi6",
    "UL / Term 30 Pay Min. Premium": "-pi7",
    "UL / Min. Premium All": "-pi8",
  },
  supplemental: {
    "Single Premium Funeral Plan": "-pi0",
    "Periodic Premium Funeral Plan": "-pi1",
    "Funeral Plan / All Products": "-pi2",
    "Accidental Life & Dismemberment": "-pi3",
  },
  "living benefits": {
    "Critical Illness / Term 10": "-pi0",
    "Critical Illness / Term 15": "-pi1",
    "Critical Illness / Term 20": "-pi2",
    "Critical Illness / Term 25": "-pi3",
    "Critical Illness / Term 30": "-pi4",
    "Critical Illness / Term 65": "-pi5",
    "Critical Illness / Term 70": "-pi6",
    "Critical Illness / Term 75": "-pi7",
    "Critical Illness / T100 - Life Pay": "-pi8",
    "Critical Illness / T100 - Pay to 65": "-pi9",
    "Critical Illness / T100 - 10-20 Pay": "-pi10",
    "Critical Illness / Conditional Limited Pay": "-pi11",
    "Critical Illness / Decreasing": "-pi12",
    "Combined Life & CI/25%": "-pi13",
    "Combined Life & CI/50%": "-pi14",
    "Combined Life & CI/75%": "-pi15",
    "Critical Illness - All Products": "-pi16",
  },
  mortgage: {
    "10 yr term": "-pi0",
    "11-14 yr Term": "-pi1",
    "15 yr Term": "-pi2",
    "16-19 yr Term": "-pi3",
    "20 yr Term": "-pi4",
    "21-24 yr Term": "-pi5",
    "25 yr Term": "-pi6",
    "26-29 yr Term": "-pi7",
    "30 yr Term": "-pi8",
    "31-34 yr Term": "-pi9",
    "35 yr Term": "-pi10",
    "36-39 yr Term": "-pi11",
    "40 yr Term": "-pi12",
    "All Products": "-pi13",
  },
};

const UnderwritingRisk = {
  Elite: "-rSP",
  Preferred: "-rP",
  Regular: "-rR",
  Simplified: "-rS",
  Guranteed: "-rG",
};

const tobaccoLevel = {
  Never: 100,
  "last 12 months": 0,
  "1 year": 1,
  "2 years": 2,
  "3 years": 3,
  "4 years": 4,
  "5 years": 5,
  "6 years": 6,
  "10 years": 10,
  "15 years": 15,
  "25 years": 25,
};

const tobaccoType = {
  cigarette: "-tucgt",
  cigar: "-tucgr",
  cigarello: "-tucgl",
  pipe: "-tupip",
  "chewing tobacco": "-tuchw",
  marijuana: "-tumrj",
  other: "-tuoth",
  "vape / e-cigarette": "-tuvap",
  "water / sacramental pipe": "-tuwat",
};
