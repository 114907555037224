import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
//import Footer from "../components/Footer";
import SEO from "../components/Seo";
import Helmet from "react-helmet";

import loadingGIF from "../images/loading.gif";
import quoteGraphic from "../images/quotegraphic.png";
import nomedical from "../images/noMedicalIcon.png";
import medical from "../images/traditionalIcon.png";
import nomedicalCheck from "../images/check-nomed.png";
import medicalCheck from "../images/check-trad.png";
import advisor from "../images/advisor.png";
import { getQuote } from "../components/Quote";
//import { url } from "vfile-message";

const IndexPage = (props) => {
  const [loading, setLoading] = useState(true);

  const [quotes, setQuotes] = useState({});

  const [start, setStart] = useState({ open: false, url: null });

  const [newQuote, setNewQuote] = useState(false);

  const params = new URLSearchParams(props.location.search);

  useEffect(() => {
    if (
      params.get("name") &&
      params.get("product") &&
      params.get("faceamount") &&
      params.get("producttype") &&
      params.get("gender") &&
      params.get("birthdate") &&
      params.get("tobacco") &&
      params.get("province") &&
      params.get("email")
    ) {
      getQuoteLocal();
    } else {
      setLoading(false);
      setNewQuote(true);
    }
  }, []);

  async function getQuoteLocal() {
    try {
      setLoading(true);

      setQuotes(await getQuote(params));

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setNewQuote(true);
    }
  }

  const renderQuotes = () => {
    if (loading) {
      return <img src={loadingGIF} alt="loading"></img>;
    }

    if (newQuote) {
      return (
        <a
          className="quotebutton"
          href="https://lifeplaninvestments.ca/onboarding"
        >
          Get A Quote
        </a>
      );
    }

    return (
      <>
        {/* <niroflow
                  style={start ? {} : { display: "none" }}
                  niroflow-id="lifeplan-application"
                  width="100%"
                  height="100%"
                  pass-parameters="true"
                  dynamic-height="true"
                ></niroflow> */}
        {start.open && start.url && (
          <div className="formsection">
            <div className="uk-flex uk-flex-right">
              <button
                className="editbutton"
                onClick={() => setStart({ open: false, url: null })}
              >
                Edit Plan
              </button>
            </div>
            <div className="formdiv">
              <iframe
                style={{
                  background: "url(" + loadingGIF + ")",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center top",
                  border: "none",
                  padding: 0,
                  margin: 0,
                  width: "100%",
                  height: "100%",
                  minHeight: "2000px",
                }}
                title="application"
                src={`https://heyflow.id/${start.url}`}
              />
            </div>
          </div>
        )}
        {!start.open && (
          <div className="uk-flex quotesdiv">
            <div className="quotestile">
              <div>
                <h3>No Medical</h3>
                <img src={nomedical} alt="no medical" />
                <h4>${quotes.No_Medical}/month</h4>
                <small>Estimated monthly premium</small>
                <p>
                  <button
                    className="buttonnomed"
                    onClick={() =>
                      setStart({ open: true, url: "lifeplan-application" })
                    }
                  >
                    Start Application
                  </button>
                </p>
              </div>
              <p className="quotestileline"></p>
              <div className="quotestiledetails">
                <p>
                  <img src={nomedicalCheck} alt="yellow checkmark" /> Instant
                  coverage
                </p>
                <p>
                  <img src={nomedicalCheck} alt="yellow checkmark" />
                  No medical required
                </p>
                <p>
                  <img src={nomedicalCheck} alt="yellow checkmark" />
                  Guaranteed approval
                </p>
              </div>
            </div>
            <div className="quotestile">
              <div>
                <h3>Traditional</h3>
                <img src={medical} alt="no medical" />
                <h4>${quotes.Lowest}/month</h4>
                <small>Estimated monthly premium</small>
                <p>
                  <button
                    className="buttonmed"
                    onClick={() =>
                      setStart({
                        open: true,
                        url: "lifeplan-qualify",
                      })
                    }
                  >
                    Qualify Now
                  </button>
                </p>
              </div>
              <p className="quotestileline"></p>
              <div className="quotestiledetails">
                <p>
                  <img src={medicalCheck} alt="blue checkmark" />
                  Our lowest rate
                </p>
                <p className="bottomborder">
                  <img src={medicalCheck} alt="blue checkmark" />
                  Increased flexibility
                </p>
                <p>
                  <img src={medicalCheck} alt="blue checkmark" />
                  Personalized coverage
                </p>
              </div>
            </div>
          </div>
        )}{" "}
      </>
    );
  };

  return (
    <Layout location={props.location}>
      <SEO title="LifePlan - Life Insurance Application" />
      <Helmet>
        <meta name="robots" content="noindex" />
        <script src="https://storage.googleapis.com/niro-eu-static/widget/v1-2-3/index.min.js"></script>
      </Helmet>
      <div id="home-page" className="uk-container uk-padding-remove uk-flex">
        <div className="sitewrapper">
          <div id="hometopsection" className="uk-container hometopsection">
            <div className="container content">
              <div className="container content">
                <div className="uk-flex hometopdiv">
                  <div className="uk-flex hometopleft">
                    <div>
                      <h2>Congratulations {params.get("name")}!</h2>
                      <h1>Here are your {params.get("product")} options</h1>
                    </div>
                  </div>
                  <div className="hometopright">
                    <img src={quoteGraphic} alt="quote graphic" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-container homesecondsection">
            <div className="container content">
              <div className="homeseconddiv">
                <div className="uk-flex uk-flex-between personaldetails">
                  <p>Your Details</p>
                  <p>
                    <span>Age:</span>
                    {params.get("birthdate")}
                  </p>
                  <p>
                    <span>Sex:</span>
                    {params.get("gender")}
                  </p>
                  <p>
                    <span>Smoking:</span>
                    {params.get("tobacco")}
                  </p>
                </div>
                <div className="uk-flex uk-flex-between policydetails">
                  <p>Policy Details</p>
                  <p>
                    <span>Coverage Amount</span>${params.get("faceamount")}
                  </p>
                  <p>
                    <span>Term Length</span>
                    {params.get("producttype")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-container homethirdsection">
            <div className="container content">
              <div className="homethirddiv">
                <p>
                  LifePlan offers two options for life insurance based on your
                  needs. A no medical option provides instant coverage without
                  the hassle of medical exams. The traditional option offers our
                  lowest rate with increased customizations and a more
                  personalized coverage.
                </p>
                {renderQuotes()}
                <p className="homethirdquestions">
                  Have questions? Our life insurance specialist will gladly
                  assist you
                </p>
                <div className="uk-flex homethirdadvisor">
                  <img src={advisor} alt="akil" />
                  <div className="homethirdadvisordetails">
                    <p>Ahilan (AKIL) Balachandran</p>
                    <p>
                      (905) 294-7526 Ext. 1001
                      <span>ahilan@lifeplaninvestments.ca</span>
                    </p>
                    <p>
                      I will be your go-to specialist if you require assistance
                      at any part of the process. Feel free to reach out if you
                      have any questions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
